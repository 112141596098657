<template>
    <a-spin :spinning="spinning">
        <ele-table
            ref="rt"
            :columns="columns"
            :table-data="data"
            :total-items="totalItems"
            :actions-type="actionsType"
            :is-full="true"
            :current-page="pageNumber"
            :hide-row-selection="true"
            @emitRefreshData="clearFilterAndRefresh"
            @emitSortData="updateSortData"
            @emitSelectChange="updateSelectChange"
            @emitOnPageChange="updatePageChange"
            @emitShowSizeChange="updateShowSizeChange"
        >
            <!-- 查询 -->
            <a-row :gutter="8">
                <a-col class="gutter-row" :span="4">
                    <a-input v-model.trim="SearchDto.keyword"
                             :placeholder="l('姓名/手机号/身份证号')"
                             allow-clear />
                </a-col>
                <a-col class="gutter-row" :span="4">
                    <a-button type="primary"
                              @click="getData()"
                              v-if="isGranted('Pages.Termination.Query')"> 
                        {{ l("Search") }}
                    </a-button>
                    <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
                </a-col>
                <a-col class="gutter-row" :span="12" align="right">
                    <a-button v-if="isGranted('Pages.Termination.Export')" type="primary"
                              @click="exportList()">
                        <a-icon type="arrow-down" />
                        <span>{{ l("ExportUserDownloadConfig") }}</span>
                    </a-button>
                    <a-button v-if="isGranted('Pages.Termination.Import')" type="danger"
                              @click="importList()">
                        <a-icon type="arrow-up" />
                        <span>{{ l("导入") }}</span>
                    </a-button>
                </a-col>
            </a-row>
        </ele-table>
    </a-spin>
</template>
<script>
    import { AppComponentBase } from "@/shared/component-base";
    import { ModalHelper } from "@/shared/helpers";
    import { CommonServiceProxy, initFieldLibOptions } from "@/shared/common-service";
    import { fileDownloadService } from "@/shared/utils";
    import moment from "moment";
    import EleTable from "@/components/ele-table";
    import ReactModify from "@/components/react-modify";
    import { AppConsts } from "@/abpPro/AppConsts";
    import { environment } from "@/environments/environment";
    import ImportForm from './import.vue';
    import CompleteForm from './complete.vue';

    export default {
        mixins: [AppComponentBase],
        name: "sohoindustry-index",
        components: { EleTable },
        data() {
          return {
            spinning: false,
            selectedRowKeys: [],
            SearchDto: {
                keyword: ''
            },
            // 总记录数
            totalItems: 0,
            // 当前页码
            pageNumber: 1,
            // 总页数
            totalPages: 1,
            // 
            pagerange: [1, 1],
            // 每页显示数
            pageSizeOptions: ["10", "20", "30", "40"],
            request: { sorting: "", maxResultCount: 50, skipCount: 0 },
            // 表头
            columns: [],
            actionsType: null,
            // 数据
            data: [],
            ComponentList: []
          };
        },
        created() {
            this.getData();
            this.initActionsType();
            this.initColums();
        },
        methods: {
            initColums() {
                let _this = this;
                this.columns = [
                    {
                        title: "姓名",
                        dataIndex: "realName",
                        sorter: false,
                        align: "center",
                        scopedSlots: { customRender: "realName" },
                    },
                    {
                        title: "身份证号",
                        dataIndex: "idCard",
                        sorter: false,
                        align: "center",
                        scopedSlots: { customRender: "idCard" },
                    },
                    {
                        title: "手机号",
                        dataIndex: "phoneNumber",
                        sorter: false,
                        align: "center",
                        // width: 100,
                        scopedSlots: { customRender: "phoneNumber" }
                    },
                    {
                        title: "所属银行",
                        dataIndex: "bankName",
                        sorter: false,
                        align: "center",
                        // width: 100,
                        scopedSlots: { customRender: "bankName" }
                    },
                    {
                        title: "银行卡号",
                        dataIndex: "bankCardNo",
                        sorter: false,
                        align: "center",
                        // width: 100,
                        scopedSlots: { customRender: "bankCardNo" }
                    },
                    {
                        title: "报备单号",
                        dataIndex: "orderNo",
                        sorter: false,
                        align: "center",
                        scopedSlots: { customRender: "orderNo" }
                    },
                    {
                        title: "认证完成时间",
                        dataIndex: "authTime",
                        sorter: false,
                        align: "center",
                        scopedSlots: { customRender: "authTime" }
                    },
                    {
                        title: "注销状态",
                        dataIndex: "status",
                        sorter: false,
                        align: "center",
                        type: 'custom',
                        scopedSlots: { customRender: "status" },
                        customRender: (text) => {
                          if (text == "Terminating" || text == 1) {
                            return "注销中";
                          }
                          else if (text == "Terminated" || text == 2) {
                            return "已注销";
                          }
                          else {
                            return "未申请";
                          }
                        },
                    },
                    {
                        title: "发起注销时间",
                        dataIndex: "applyTime",
                        sorter: false,
                        align: "center",
                        scopedSlots: { customRender: "applyTime" }
                    },
                    {
                        title: "注销完成时间",
                        dataIndex: "completeTime",
                        sorter: false,
                        align: "center",
                        scopedSlots: { customRender: "completeTime" }
                    }
                ];
            },
            // 初始化列表action
            initActionsType() {
                let _this = this;
                let obj = {
                    type: "fieldResource",
                    isShow: true,
                    fns: {
                        detailList: [
                            {
                                granted: this.isGranted("Pages.Termination.Cancel"),
                                name: this.l("撤销申请"),
                                icon: "profile",
                                width: "30%",
                                fn: (data) => {
                                  _this.handleCancel(data);
                                },
                            },
                            {
                                granted: this.isGranted("Pages.Termination.Complete"),
                                width: "30%",
                                name: this.l("解约完成"),
                                icon: "profile",
                                fn: (data) => {
                                    _this.handleComplete(data);
                                },
                            }
                        ],
                        dropList: []
                    },
                };
                this.actionsType = obj;
            },
            getData () {
                let data = {
                    keyword: this.SearchDto.keyword,
                    sorting: this.request.sorting,
                    skipCount: this.request.skipCount,
                    maxResultCount: this.request.maxResultCount
                };
                this.$nextTick(() => {
                    this.$refs.rt.clearData();
                })
                let _this = this;
                this.SendToService({
                    url: '/api/services/app/CommericalRegist/GetTerminateApplyByPage',
                    method: 'POST',
                    data: data,
                    success(res) {
                        _this.data.splice(0, _this.data.length);
                        _this.totalItems = res.totalCount;
                        res.items.forEach(item => _this.data.push(item));
                        _this.data = res.items.map(item => {
                            return {
                                ...item
                            }
                        })
                    }
                });
            },
            showTotalFun() {
                return this.l(
                    "GridFooterDisplayText",
                    this.pageNumber,
                    this.totalPages,
                    this.totalItems,
                    this.pagerange[0],
                    this.pagerange[1]
                );
            },
            refreshGoFirstPage() {
                this.SearchDto.keyword = '';
                this.pageNumber = 1;
                this.request.SkipCount = 0;
                this.getData();
            },
            handleComplete(data) {
                let _this = this;
                if (data.status != 1 && data.status != 'Terminating') {
                  abp.message.warn('只有解约中的状态才能执行此操作');
                  return false;
                }
                ModalHelper.create(
                  CompleteForm,
                  { id: data.id },
                  {
                    width: "450px",
                  }
                ).subscribe((res) => {
                  if (res) {
                    this.refreshGoFirstPage();
                  }
                });
                // abp.message.confirm('是否确认此解约申请已完成？', '提示', (fn) => {
                //     if(fn) {
                //         _this.SendToService({
                //             url: '/api/services/app/CommericalRegist/TerminationComplete',
                //             method: 'get',
                //             params: {id: id},
                //             success(res) {
                //                 abp.message.success('操作成功');
                //                 _this.refreshGoFirstPage();
                //             }
                //         });
                //     }
                // });
            },
            handleCancel(data) {
                let _this = this;
                if (data.status != 1 && data.status != 'Terminating') {
                  abp.message.warn('只有解约中的状态才能执行此操作');
                  return false;
                }
                abp.message.confirm('是否撤销此解约申请？', '提示', (fn) => {
                    if(fn) {
                        _this.SendToService({
                            url: '/api/services/app/CommericalRegist/CancelTermination',
                            method: 'post',
                            params: {id: data.id},
                            success(res) {
                                abp.message.success('撤销解约申请成功');
                                _this.refreshGoFirstPage();
                            }
                        });
                    }
                });
            },
            SendToService(opts) {
                this.spinning = true;
                let options = {
                    url: this.$apiUrl + opts.url,
                    method: opts.method,
                    data: opts.data,
                    params: opts.params,
                    headers: { "Content-Type": "application/json-patch+json" },
                };
                this.$api.request(options).then((res) => {
                    this.spinning = false;
                    if (opts.complete && typeof opts.complete == 'function') {
                        opts.complete(res);
                    }
                    if (res.status == 200) {
                        if (opts.success && typeof opts.success == 'function') {
                            opts.success(res.data);
                        }
                    } else {
                        abp.message.error(res.statusText);
                    }
                }).catch(e => {
                    this.spinning = false;
                    if (opts.complete && typeof opts.complete == 'function') {
                        opts.complete(e);
                    }
                    console.error(e);
                });
            },
            exportList () {
              let data = {
                  keyword: this.SearchDto.keyword,
                  sorting: this.request.sorting,
                  skipCount: this.request.skipCount,
                  maxResultCount: this.request.maxResultCount
              };
              this.$nextTick(() => {
                  this.$refs.rt.clearData();
              })
              let _this = this;
              this.SendToService({
                  url: '/api/services/app/CommericalRegist/Export',
                  method: 'POST',
                  data: data,
                  success(res) {
                    fileDownloadService.downloadTempFile(res);
                  }
              });
            },
            importList () {
              ModalHelper.create(
                ImportForm,
                { id: "" },
                {
                  width: "860px",
                }
              ).subscribe((res) => {
                this.refreshGoFirstPage();
              });
            },
            /*
            分页相关
            */
            // 情况查询条件并重新查询
            clearFilterAndRefresh() {
                this.request = { sorting: "", maxResultCount: 50, skipCount: 0 };
                this.SearchDto.Name = "";
                this.SearchDto.FieldType = undefined;
                this.getData();
            },
            // 排序更新data
            updateSortData(newV) {
                let { columnKey, order } = newV;
                this.request.sorting = order ? `${columnKey} ${order}` : "";
                this.getData();
            },
            // table选择事件
            updateSelectChange(newV) {
                let { selectedRowKeys, selectedRows } = newV;
                this.onSelectChange(selectedRowKeys, selectedRows);
            },
            onSelectChange(keys, rows) {
                if(!!this.selectedRows){
                    this.selectedRows.splice(0, this.selectedRows.length);
                    for (let i = 0; i < rows.length; i++) {
                        this.selectedRows.push(rows[i]);
                    }
                }
            },
            // 分页
            updatePageChange(newV) {
                let { page, pageSize } = newV;
                this.onChange(page, pageSize);
            },
            onChange(page, pageSize) {
                this.pageNumber = page;
                this.request.SkipCount =
                    (page - 1) * this.request.maxResultCount;
                this.getData();
            },
            updateShowSizeChange(newV) {
                let { current, size } = newV;
                this.showSizeChange(current, size);
            },
            showSizeChange(current, size) {
                this.pageNumber = 1;
                this.request.maxResultCount = size;
                this.getData();
            }
        }
    };
</script>

<style lang="less" scoped>
    .btn--container {
        margin-bottom: 20px;
    }

    .btn--footer {
        // border: 1px solid #e8e8e8;
        // margin: 20px;
        // padding: 20px;
        .table-delete

    {
        margin-left: 10px;
        margin-right: 10px;
    }

    }

    .btn--footer {
        margin-bottom: 20px;
    }
</style>
