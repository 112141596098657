<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span>{{ l("个体工商注销") }}</span>
      </div>
    </div>
    <a-row :gutter="16" class="btn--container">
      <a-col :span="16" style="text-align: left">
        <a-date-picker show-time placeholder="注销完成时间" v-model="completeTime" />
      </a-col>
    </a-row>
    <a-row :gutter="16" class="upload--container">
      <a-col class="gutter-row" :span="24">
        <a-button type="danger" @click="handleSave">保存</a-button>
        <a-button type="default" @click="handleClose">取消</a-button>
      </a-col>
    </a-row>
  </a-spin>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { setInterval, setTimeout } from "timers";
import { AppConsts } from "@/abpPro/AppConsts";
import { fileDownloadService } from "@/shared/utils";

export default {
  mixins: [ModalComponentBase],
  name: "import-form",
  data() {
    return {
      completeTime: null,
      spinning: false,
      id: ''
    };
  },
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
  },
  methods: {
    handleSave () {
      let _this = this;
      abp.message.confirm('是否确认此注销申请已完成？', '提示', (fn) => {
        if(fn) {
            _this.SendToService({
                url: '/api/services/app/CommericalRegist/TerminationComplete',
                method: 'post',
                data: {id: _this.id.toString(), completeTime: _this.completeTime },
                success(res) {
                    abp.message.success('操作成功');
                    _this.success(true);
                }
            });
        }
      });
    },
    handleClose () {
      this.close()
    },
    SendToService(opts) {
      this.spinning = true;
      let options = {
          url: this.$apiUrl + opts.url,
          method: opts.method,
          data: opts.data,
          params: opts.params,
          headers: { "Content-Type": "application/json-patch+json" },
      };
      this.$api.request(options).then((res) => {
          this.spinning = false;
          if (opts.complete && typeof opts.complete == 'function') {
              opts.complete(res);
          }
          if (res.status == 200) {
              if (opts.success && typeof opts.success == 'function') {
                  opts.success(res.data);
              }
          } else {
              abp.message.error(res.statusText);
          }
      }).catch(e => {
          this.spinning = false;
          if (opts.complete && typeof opts.complete == 'function') {
              opts.complete(e);
          }
          console.error(e);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.btn--container {
  margin: 0 20px !important;
}
.upload--container {
  margin: 20px !important;
}
</style>
